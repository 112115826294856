// src/components/Hero/Effects/ArrowHint/ArrowHint.js
import React from 'react';
import './ArrowHint.css';
import ArrowIcon from '../../../../assets/hero/arrow-down.png';

const ArrowHint = ({ show }) => {
  if (!show) return null;

  return (
    <div className="arrow-hint-container">
      <div
        className="arrow-hint"
        style={{
          backgroundImage: `url(${ArrowIcon})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'contain'
        }}
      />
      <p className="arrow-text">Выбранные объекты</p>
    </div>
  );
};

export default ArrowHint;
