import React, { useState, useEffect } from 'react';
import './Hero.css';
import SvgComponent from './SVGComponent/SvgComponent';
import ClickableHint from './Effects/ClickableHint/ClickableHint';
import ArrowHint from './Effects/ArrowHint/ArrowHint';

const Hero = ({ setSelectedArea }) => {
  const [highlightedArea, setHighlightedArea] = useState(null);
  const [showHint, setShowHint] = useState(true);
  const [showArrow, setShowArrow] = useState(false);

  const hideHint = () => {
    setShowHint(false);
  };

  const handleRegionClick = () => {
    hideHint();
    setShowArrow(true);
  };

  useEffect(() => {
    if (showArrow) {
      const timer = setTimeout(() => setShowArrow(false), 5000); // Скрываем стрелку через 5 секунд
      return () => clearTimeout(timer);
    }
  }, [showArrow]);

  return (
    <section className='hero-container'>
      <div className='hero-content'>
        <h2>Недвижимость в лучших <br /> жилых комплексах Москвы</h2>
        <p>Воплощаем мечты об идеальной жизни в гармоничном пространстве. Работаем со знанием дела и без комиссии.</p>
      </div>
      <div className='hero-map'>
        <SvgComponent
          highlightedArea={highlightedArea}
          setSelectedArea={setHighlightedArea}
          onRegionClick={handleRegionClick} // Передаем handleRegionClick
          className="moscow-map"
        />
        {showHint && <ClickableHint />}
        <ArrowHint show={showArrow} />
      </div>
    </section>
  );
};

export default Hero;
