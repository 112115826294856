import React, { useEffect, useState } from 'react';
import './Modal.css'; // стили для модального окна
import ModalContent from './ModalContent/ModalContent';

const Modal = ({ card, onClose }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false); // Следим за состоянием видео

  useEffect(() => {
    const updateIsMobile = () => setIsMobile(window.innerWidth <= 768);
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
    return () => window.removeEventListener('resize', updateIsMobile);
  }, []);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  if (!card) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      if (isPlaying) {
        // Если видео воспроизводится, только останавливаем его
        setIsPlaying(false);
      } else {
        // Если видео не воспроизводится, закрываем модальное окно
        onClose();
      }
    }
  };

  return (
    <div
      className={`modal-overlay ${isPlaying ? 'blurred-background' : ''}`}
      onClick={handleOverlayClick}
    >
      <div className="modal-content-wrapper">
        <ModalContent
          card={card}
          onClose={onClose}
          isMobile={isMobile}
          setIsPlaying={setIsPlaying} // Передаем управление состоянием воспроизведения
        />
      </div>
    </div>
  );
};

export default Modal;
