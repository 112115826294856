import React, { useState, useEffect } from 'react';
import './ClickableHint.css';
import HandIcon from '../../../../assets/hero/click-mouse.png';

const ClickableHint = () => {
  const [currentPositionIndex, setCurrentPositionIndex] = useState(0);
  const [rippleKey, setRippleKey] = useState(0);

  // Позиции для курсора и ряби
  const positions = [
    { top: '20%', right: '10%' },
    { top: '40%', right: '25%' },
    { top: '60%', right: '15%' }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPositionIndex((prevIndex) => (prevIndex + 1) % positions.length);
      setRippleKey((prevKey) => prevKey + 1); // Меняем ключ для ряби
    }, 5000); // Меняем позицию каждые 5 секунд

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className="clickable-hint"
      style={positions[currentPositionIndex]}
    >
      {/* Используем уникальный ключ для перезапуска анимации ряби */}
      <span key={rippleKey} className="ripple" />
      <img src={HandIcon} alt="Нажмите" className="hand-icon" />
      <p className="hint-text">Можно выбрать жилые комплексы <br/> в конкретных административных округах</p>
    </div>
  );
};

export default ClickableHint;
