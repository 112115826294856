import React, { useState, useRef, useEffect } from 'react';
import './ModalContent.css';
import CatalogDownloadHandler from './CatalogDownloadHandler/CatalogDownloadHandler';
import { Link } from 'react-router-dom';
import PlayBtn from '../../../assets/modal/play.png';
import CustomPoster from '../../../assets/appartment-images/shagal.jpg';

const ModalContent = ({ card, onClose, setIsPlaying }) => {
  const [isSent, setIsSent] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [progress, setProgress] = useState(0); // Состояние для прогресса
  const [showFormAnimation, setShowFormAnimation] = useState(false); // Анимация формы
  const videoRef = useRef(null);

  const handleSendSuccess = () => {
    setIsSent(true);
    setTimeout(() => {
      if (onClose) {
        onClose();
      }
    }, 5000);
  };

  const toggleVideoPlay = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true); // Устанавливаем состояние воспроизведения
        setIsExpanded(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false); // Обновляем состояние на паузу
        setIsExpanded(false);
        triggerAnimation();
      }
    }
  };

  const handleVideoEnded = () => {
    setIsPlaying(false); // Видео завершено
    setIsExpanded(false);
    setProgress(0); // Сбрасываем прогресс
    triggerAnimation();
  };

  // Функция для включения анимации формы
  const triggerAnimation = () => {
    setShowFormAnimation(false); // Сбрасываем состояние анимации
    setTimeout(() => setShowFormAnimation(true), 0); // Включаем анимацию
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (videoRef.current && !videoRef.current.paused) {
        const progressPercentage =
          (videoRef.current.currentTime / videoRef.current.duration) * 100;
        setProgress(progressPercentage || 0);
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="modal-overlay-content">
      <button className="modal-close-button" onClick={onClose}>
        ×
      </button>
      {!isSent ? (
        <>
          <div className="modal-content-left">
            <img src={card.catalogImage} alt="ЖК" className="jk-image" />
            <div
              className={`form-container ${
                showFormAnimation ? 'animated' : ''
              }`}
              onAnimationEnd={() => setShowFormAnimation(false)} // Убираем класс анимации после завершения
            >
              <p className="modal-description">
                Оставьте обращение, и наш менеджер свяжется с вами и все
                расскажет.
              </p>
              <CatalogDownloadHandler
                card={card}
                catalogUrl="https://storage.yandexcloud.net/bytehub/real-estate/pdf/sample-pdf.pdf"
                onSendSuccess={handleSendSuccess}
              />
              <p className="modal-note">
                *Оставляя заявку на сайте, вы соглашаетесь с нашей{' '}
                <Link to="/privacy-policy">Политикой конфиденциальности</Link>.
              </p>
            </div>
          </div>
          {card.avatarUrl ? (
            <div className="modal-content-right">
              <div
                className={`video-container ${isExpanded ? 'expanded' : ''}`}
                onClick={toggleVideoPlay}
              >
                <div className="progress-bar" style={{ width: `${progress}%` }} />
                {(!isExpanded || videoRef.current?.paused) && (
                  <div className="video-overlay">
                    <img src={PlayBtn} alt="Play" className="play-icon" />
                  </div>
                )}
                <video
                  ref={videoRef}
                  className={`catalog-video ${isExpanded ? 'expanded' : ''}`}
                  src={card.avatarUrl}
                  poster={CustomPoster}
                  onEnded={handleVideoEnded}
                  playsInline
                />
              </div>
            </div>
          ) : (
            <div className="modal-content-right no-video">
              <p>Видеопрезентация в процессе разработки, но Вы можете оставить обращение, наши специалисты рады ответить на все интересующие вопросы.</p>
            </div>
          )}
        </>
      ) : (
        <div className="modal-success-message">
          Удачного просмотра! Окно закроется через 5 секунд.
        </div>
      )}
    </div>
  );
};

export default ModalContent;
